import QrCodeGenerator from "./components/QrCodeGenerator";


function App() {
  
  return (
    <>
      <QrCodeGenerator/>
    </>
  );
}

export default App;
