
//https://qr.xorblock.com/?service=suipay&seller-wallet=0x2b6f6d3c8bb5c400dea46bf37cc178ed6722bae9460bd68715d7e3f25c9f1147&coin=USD&sui-rate=1.0320000&product-price=25
//import { useState, useRef } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import '../index.css'


function QrCodeGenerator() {

  const queryParameters = new URLSearchParams(window.location.search);
  const price = queryParameters.get("product-price");
  const swallet = queryParameters.get("seller-wallet");
  const sui2usd = queryParameters.get("sui-rate");  
  const coin = queryParameters.get("coin");
  const pay = queryParameters.get("service");
  //const [url, setUrl] = useState("");
  /*const [qrIsVisible, setQrIsVisible] = useState(false);

  const handleQrCodeGenerator = () => {
    if (!price) {
      return;
    }

    setQrIsVisible(true);
  }; */

  //const qrCodeRef = useRef(null);

  /*const downloadQRCode = () => {
    htmlToImage
      //.toPng(qrCodeRef.current)
      .toPng("https://suipay.xorblock.com/?seller-wallet=0x2b6f6d3c8bb5c400dea46bf37cc178ed6722bae9460bd68715d7e3f25c9f1147&coin=USD&sui-rate=1.07250000&product-price=3")
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  }; */

  const url = 'https://' + pay + '.xorblock.com/?seller-wallet=' + swallet + '&coin=' + coin + '&sui-rate=' + sui2usd + '&product-price=' + price;

  return (
    <div className="qrcode__container">
        
        {price &&  swallet && sui2usd && coin &&  pay && (
     
            <div className="qrcode__image">
              <QRCode value={url} size={300} />
            </div>
           
        )}
      </div>
    
  );
}

export default QrCodeGenerator;
